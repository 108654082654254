import { useEffect, useState } from "react";
import { NavBarComponent } from "../components/NavBarComponent";
import { TopScreenComponent } from "../components/TopScreenComponent";
import styled from "styled-components";
import { createDesposit, db } from "../firebase";
import {
  collection,
  doc,
  endBefore,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";

/* 3. 콜백 함수 정의하기 */
function callback(response) {
  // const { success, merchant_uid, error_msg } = response;
  const { success, error_msg } = response;

  if (success) {
    alert("결제 성공");
  } else {
    alert(`결제 실패: ${error_msg}`);
  }
}

const Container = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  header {
    //회색
    background-color: #f5f5f5;
    color: #333;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
  }

  .pointChargingBox {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  h1 {
    margin: 0;
  }

  .current-points {
    font-size: 20px;
    font-weight: bold;
    //연한 빨강색으로 추천
    color: #ff0000;
    margin-bottom: 20px;
  }

  .recharge-form {
    margin-bottom: 20px;
    select#amount {
      width: 100%;
      padding: 20px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      background-color: #ffffff;
    }
  }

  label {
    font-weight: bold;
  }

  input[type="text"] {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }

  button {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 25px;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #0056b3;
  }
  .receipt {
    animation: fadeIn 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .receipt-check {
    display: flex;
    align-items: center;
  }

  .receiptInputBox {
    padding: 20px 0;
    display: flex;
  }

  .receiptInputBox input {
    width: 100%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }

  .pointHistory {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    background-color: #f5f5f5;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }

  th {
    font-weight: bold;
  }

  .chargingMethod {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;

    .customRadio {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      margin-right: 15px;
      cursor: pointer;
      font-size: 16px;
      user-select: none;
    }

    .customRadio input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
    }

    .customRadio:hover input ~ .checkmark {
      background-color: #f5f5f5;
    }

    .customRadio input:checked ~ .checkmark {
      background-color: #007bff;
      border-color: #007bff;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .customRadio input:checked ~ .checkmark:after {
      display: block;
    }

    .customRadio .checkmark:after {
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .pointChargingBox {
      //boxsize
      width: 100%;
      box-sizing: border-box;
    }
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Depositprecautions = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  h4 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    margin-bottom: 10px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const PointHistoryTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const PrevButton = styled.span`
  display: inline-block;
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const NextButton = styled.span`
  display: inline-block;
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export default function Payment({ userObj, userGetLoading, userPoint }) {
  const [method, setMethod] = useState("method1");
  const [receiptCheck, setReceiptCheck] = useState(false);
  const [amount, setAmount] = useState(0);
  const [receipt, setReceipt] = useState("");
  const [isDeposit, setIsDeposit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [desposits, setDesposits] = useState([]);
  const [despositFirstId, setDespositFirstId] = useState(null);
  const [last, setLast] = useState(null);

  function onClickPayment() {
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init("imp34388066");

    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg: "kakaopay",
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      amount: 1000, // 결제금액
      name: "포인트충전10000", // 주문명
      buyer_name: "홍길동", // 구매자 이름
      buyer_tel: "01012341234", // 구매자 전화번호
      buyer_email: "example@example", // 구매자 이메일
      buyer_addr: "신사동 661-16", // 구매자 주소
      buyer_postcode: "06018", // 구매자 우편번호
      m_redirect_url: "https://bus-403.pages.dev/payment", // 결제 완료 후 보낼 링크
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  }

  const onclickMakeDespoit = async () => {
    if (amount === 0) {
      setErrorMsg("충전할 포인트를 선택해주세요.");
      return;
    }
    if (receiptCheck && receipt === "") {
      setErrorMsg("휴대폰 번호 혹은 사업자 번호를 입력해주세요.");
      return;
    }
    await createDesposit(userObj.uid, amount, receipt, receiptCheck);
    setIsDeposit(true);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleMethodChange = (event) => {
    setMethod(event.target.value);
  };

  const handleReceiptChange = (event) => {
    setReceipt(event.target.value);
  };

  const onDespoitCancel = async () => {
    const docRef = doc(db, "desposits", despositFirstId);
    const docSnap = await getDoc(docRef);
    //첫번째 문서의 상태가 입금대기라면
    if (docSnap.data().status === "depositReady") {
      //입금취소로 변경
      await updateDoc(docRef, {
        status: "depositCancel",
        updatedAt: new Date(Date.now()),
      });
    }
  };

  //get desposits
  useEffect(() => {
    const userId = userObj?.uid;
    if (userId) {
      // 콜렉션에 desposits을 조회하는데 uid가 userId인것을 조회한다.
      const q = query(
        collection(db, "desposits"),
        orderBy("createdAt", "desc"),
        where("userId", "==", userId),
        limit(10)
      );
      //스냅샷 말고 getDocs로 조회해도 된다.
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const desposits = [];
        querySnapshot.forEach((doc) => {
          desposits.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setDesposits(desposits);
        if (desposits.length === 0) return;
        setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
        //만약 첫번째 문서가 입금대기라면 isDeposit를 true로 설정한다.
        if (desposits.length > 0 && desposits[0].status === "depositReady") {
          setIsDeposit(true);
          setDespositFirstId(desposits[0].id);
        } else {
          setIsDeposit(false);
          setDespositFirstId(null);
        }
      });
      return () => unsubscribe();
    }
  }, [userObj]);

  //이전버튼
  const prevPage = async () => {
    const q = query(
      collection(db, "desposits"),
      orderBy("createdAt", "desc"),
      where("userId", "==", userObj.uid),
      endBefore(last),
      limit(10)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length < 10) return;
    const desposits = [];
    querySnapshot.forEach((doc) => {
      desposits.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setDesposits(desposits);
    setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  //다음버튼
  const nextPage = async () => {
    const q = query(
      collection(db, "desposits"),
      orderBy("createdAt", "desc"),
      where("userId", "==", userObj.uid),
      startAfter(last),
      limit(10)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return;
    const desposits = [];
    querySnapshot.forEach((doc) => {
      desposits.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setDesposits(desposits);
    if (desposits.length === 0) return;
    setLast(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  // useEffect(() => {
  //   // desposits가 변경되면 실행 된다
  //   if (desposits.length > 0) {
  //     //마지막 문서가 입금대기라면 isDeposit를 true로 설정한다.
  //     if (desposits[desposits.length - 1].status === "depositReady")
  //       setIsDeposit(true);
  //   }
  // }, [desposits]);

  return (
    <>
      <NavBarComponent userGetLoading={userGetLoading} userObj={userObj} />
      <TopScreenComponent userGetLoading={userGetLoading} userObj={userObj} />
      <Container>
        <div className="pointChargingBox">
          {!isDeposit ? (
            <>
              <div className="chargingMethod">
                <div className="customRadio">
                  <input
                    type="radio"
                    id="method2"
                    name="method"
                    value="method2"
                    checked={method === "method2"}
                    onChange={handleMethodChange}
                  />
                  <div className="checkmark"></div>
                  <label htmlFor="method2">무통장 입금</label>
                </div>
                <div className="customRadio">
                  <input
                    type="radio"
                    id="method3"
                    name="method"
                    value="method3"
                    checked={method === "method3"}
                    onChange={handleMethodChange}
                  />
                  <div className="checkmark"></div>
                  <label htmlFor="method3">카카오 페이</label>
                </div>
              </div>
              <header>
                <h1>포인트 충전</h1>
              </header>
              <div className="current-points">
                <p>
                  현재 포인트: <span id="points">{userPoint}</span>
                </p>
              </div>
              <div className="recharge-form">
                <select
                  id="amount"
                  value={amount}
                  onChange={handleAmountChange}
                >
                  <option selected value>
                    충전할 포인트를 선택하세요
                  </option>
                  <option value="5000">5,000원</option>
                  <option value="10000">10,000원</option>
                  <option value="20000">20,000원</option>
                  <option value="30000">30,000원</option>
                </select>
              </div>
              <div className="receipt">
                <div class="receipt-check">
                  <label for="receipt">현금 영수증 신청:</label>
                  <input
                    type="checkbox"
                    id="receipt"
                    onChange={() => setReceiptCheck(!receiptCheck)}
                  />
                </div>
                <div className="receiptInputBox">
                  <input
                    type="text"
                    placeholder={
                      receiptCheck
                        ? "휴대폰 번호 혹은 사업자 번호를 입력해주세요."
                        : "현금영수증을 원하시면 체크해주세요."
                    }
                    onChange={handleReceiptChange}
                    disabled={!receiptCheck}
                  />
                </div>
              </div>
              <ErrorMsg>{errorMsg}</ErrorMsg>
              <button
                onClick={
                  method === "method3" ? onClickPayment : onclickMakeDespoit
                }
              >
                {method === "method3" ? "결제하기" : "입금신청"}
              </button>
            </>
          ) : (
            <>
              <header>
                <h1>무통장 입금 안내</h1>
              </header>
              <h2>입금 계좌</h2>
              <p>국민은행 123456-78-901234</p>
              <h2>입금자명</h2>
              <p>홍길동</p>
              <h2>입금 금액</h2>
              <p>{amount}원</p>
              <Depositprecautions>
                <h4>주의사항</h4>
                <p>
                  입금자명과 결제자명이 다를 경우, 입금이 확인되지 않을 수
                  있습니다.
                </p>
                <p>
                  입금 확인은 최대 30분까지 소요될 수 있으며, 입금 확인 후
                  포인트가 적립됩니다.
                </p>
                <p>
                  입금 확인 후 포인트가 적립되지 않을 경우, 고객센터로 문의해
                  주세요.
                </p>
                <button onClick={onDespoitCancel}>입금취소</button>
              </Depositprecautions>
            </>
          )}
        </div>

        <div className="pointHistory">
          <PointHistoryTitle>
            <h2>포인트 내역</h2>
            <div>
              {/* 이전버튼 */}
              <PrevButton onClick={prevPage}>이전</PrevButton>
              {/* 다음버튼 */}
              <NextButton onClick={nextPage}>다음</NextButton>
            </div>
          </PointHistoryTitle>
          <table>
            <thead>
              <tr>
                <th>날짜</th>
                <th>내용</th>
                <th>금액</th>
              </tr>
            </thead>
            <tbody>
              {desposits.map((desposit) => (
                <tr key={desposit.id}>
                  <td>{desposit.createdAt.toDate().toLocaleDateString()}</td>
                  <td>
                    {desposit.status === "depositReady"
                      ? "입금대기"
                      : desposit.status === "depositComplete"
                      ? "입금완료"
                      : "입금취소"}
                  </td>
                  <td>{desposit.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
}
