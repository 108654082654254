import styled from "styled-components";
import { logout, signIn } from "../firebase";

const TopScreen = styled.div`
  background-color: #ff9eaa;
  height: 500px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  font-family: "Nanum Gothic", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  img {
    position: absolute;
    bottom: -50px;
    right: -50px;
    opacity: 0.5;
    z-index: 1;
    width: 300px;
    height: 300px;
  }
  h2 {
    color: #fff;
    font-size: 58px;
    font-weight: 700;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    font-weight: bold;
    z-index: 2;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease-in-out;

    //520px
    @media screen and (max-width: 520px) {
      font-size: 40px;
    }

    //440px
    @media screen and (max-width: 440px) {
      font-size: 30px;
    }
  }
  p {
    width: fit-content;
    color: #fff;
    font-size: 23px;
    font-weight: bold;
    font-weight: 500;
    animation: bounce 2s infinite;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin: 0;

    //440px
    @media screen and (max-width: 440px) {
      font-size: 19px;
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const LoginBox = styled.div`
  display: flex;
  gap: 20px;
  z-index: 2;
`;

const GoogleLogin = styled.button`
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  &:hover {
    background-color: #ff6666;
    color: #fff;
  }
`;

const KaKao = styled.button`
  background-color: #ffcc00;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  &:hover {
    background-color: #ff6666;
    color: #fff;
  }
`;

export function TopScreenComponent({ userObj, userGetLoading }) {
  return (
    <TopScreen>
      <img
        src="https://cdn-icons-png.flaticon.com/512/3521/3521367.png"
        alt=""
      />
      <h2>8월 최고👍의 혜택!</h2>
      <p>지금 가입하시면 즉시 1000 포인트 증정</p>
      <p>구글 로그인으로 간편하게 시작하세요</p>
      <LoginBox>
        {!userObj && !userGetLoading ? (
          <GoogleLogin onClick={signIn}>구글 로그인</GoogleLogin>
        ) : (
          <GoogleLogin onClick={logout}>로그아웃</GoogleLogin>
        )}
        <KaKao>카카오톡 문의하기</KaKao>
      </LoginBox>
    </TopScreen>
  );
}
