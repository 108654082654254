import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { orderCreate, userPointCheck } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const ModalContaier = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  position: relative;
  width: 90%;
  height: fit-content;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  animation: modal-show 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    padding: 10px;
  }

  @keyframes modal-show {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  h2 {
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
  }
  .notice {
    margin-bottom: 20px;
    color: #3a7cff;
  }

  img {
    width: 280px;
    margin: 0 auto;
    object-fit: cover;

    @media (max-width: 400px) {
      width: 220px;
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .error-msg {
    color: #ff3a47;
    margin: 0;
    padding: 0;
    animation: error-msg-show 0.3s ease-in-out;
  }

  @keyframes error-msg-show {
    //bounce
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    50% {
      transform: translateY(10px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  // .preview-image {
  //   width: 100px;
  //   heith: 100px;
  //   border: 1px solid #ddd;

  //   @media (max-width: 768px) {
  //     width: 100%;
  //     height: 100%;
  //   }

  //   @media (max-width: 400px) {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }

  .insta-link-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .insta-link {
    width: 70%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex: 1;
  }

  .order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .order input {
    flex: 1;
  }

  .quantity {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .submit {
    padding: 10px 20px;
    background-color: #ff3a47;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
  }
  .price {
    margin-left: auto;
    color: #ff3a47;
  }
  .complete-btn {
    padding: 20px 40px;
    font-size: 20px;
    background-color: #ff3a47;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin: 0 auto;
  }
  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

const ModalTitleBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  div {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
  }
  div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 20px;
  font-size: 35px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
`;

export function OrderModalComponent({
  serviceItem,
  setServiceItem,
  setModalShow,
  userObj,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState(
    "https://cdn-icons-png.flaticon.com/512/4503/4503941.png"
  );
  const [link, setLink] = useState(null); // [link, setLink
  const [quantity, setQuantity] = useState(null);
  const [totalPrice, setTotalPrice] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isComplete, setIsComplete] = useState(false); // [isComplete, setIsComplete
  const [imgLoading, setImgLoading] = useState(false); // [imgLoading, setImgLoading

  const modalClose = (e) => {
    if (e.target === e.currentTarget) {
      setModalShow(false);
    }
  };

  const onModalKeyDown = (e) => {
    if (e.key === "Escape") {
      setModalShow(false);
    }
  };

  const onSubmit = async () => {
    if (link === null) return setErrorMessage("링크를 입력해주세요.");
    if (quantity === null) return setErrorMessage("수량을 입력해주세요.");

    //포인트 체크
    if (userObj) {
      if (!(await userPointCheck(userObj.uid, totalPrice)))
        return setErrorMessage("포인트가 부족합니다.");
    } else {
      return setErrorMessage("로그인이 필요합니다.");
    }

    //fetch query link 보내기
    const functions = getFunctions();

    try {
      setIsLoading(true);
      const getImgUrl = httpsCallable(functions, "on_call_example");
      const result = await getImgUrl({ link: link });

      await orderCreate(
        link,
        quantity,
        userObj.uid,
        serviceItem.id,
        serviceItem.name,
        setIsLoading,
        setIsComplete,
        totalPrice,
        serviceItem.service,
        result.data
      );
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onPreview = async () => {
    if (!userObj) {
      setErrorMessage("로그인이 필요합니다.");
      return;
    }
    if (!link) {
      setErrorMessage("링크를 입력해주세요.");
      return;
    }
    //fetch query link 보내기
    setImgLoading(true);
    const functions = getFunctions();
    const getImgUrl = httpsCallable(functions, "on_call_example");
    const result = await getImgUrl({ link: link });
    if (result.data === "") {
      setErrorMessage("이미지를 불러올 수 없습니다.");
      setImgLoading(false);
      return;
    }
    setPreviewImg(result.data);
    setImgLoading(false);
  };

  useEffect(() => {
    //body 스크롤 막기
    document.body.style.overflow = "hidden";
    //mobile
    document.addEventListener("touchmove", (e) => {
      e.preventDefault();
    });
    return () => {
      //body 스크롤 풀기
      document.body.style.overflow = "unset";
      //mobile
      document.removeEventListener("touchmove", (e) => {
        e.preventDefault();
      });
      setServiceItem(null);
    };
  }, [setServiceItem]);

  if (!serviceItem)
    return (
      <ModalContaier>
        <Modal>
          <h2>로딩중.....</h2>
        </Modal>
      </ModalContaier>
    );

  if (isComplete) {
    return (
      <ModalContaier>
        <Modal>
          <h2>주문이 완료되었습니다.</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>{link}</h4>
            <p style={{ color: "blue", fontWeight: "bold" }}>수량 {quantity}</p>
            <p style={{ color: "red", fontWeight: "bold" }}>
              차감 포인트 {totalPrice}
            </p>
            <button
              className="complete-btn"
              onClick={() => {
                setModalShow(false);
                setIsComplete(false);
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      </ModalContaier>
    );
  }

  return (
    <ModalContaier
      onKeyDown={(e) => {
        onModalKeyDown(e);
      }}
      // onClick={modalClose}
    >
      <Modal>
        <ModalCloseButton onClick={modalClose}>&times;</ModalCloseButton>
        <ModalTitleBox>
          <h2>{serviceItem.name}</h2>
          <div>
            <img src={serviceItem.image} alt="서비스 이미지" />
            {serviceItem.isKorean ? (
              <img
                src="https://cdn-icons-png.flaticon.com/512/1999/1999610.png"
                alt="대한민국"
              />
            ) : (
              <img
                src="https://cdn-icons-png.flaticon.com/512/330/330459.png"
                alt="미국"
              />
            )}
          </div>
        </ModalTitleBox>

        <div className="preview">
          {!imgLoading ? (
            <img
              className="preview-image"
              src={previewImg}
              alt="프리뷰 이미지"
            />
          ) : (
            <img
              referrerpolicy="no-referrer"
              className="preview-image"
              src="https://search.pstatic.net/common/?src=http%3A%2F%2Fcafefiles.naver.net%2FMjAxODA1MTVfMTA2%2FMDAxNTI2MzkzOTg5MDUw.-blokbEz3QiffVIUBSN4JLWa4bHCSQdK16doudGcpO8g.vwrMcRZ8loO2TreSxgLRHuk-4eqcfM9hxxIkmUdZAYcg.GIF.jungwoolee08%2FexternalFile.gif&type=sc960_832_gif"
              alt="로딩중"
            />
          )}
          {errorMessage && <p className="error-msg">{errorMessage}</p>}
          <div className="insta-link-box">
            <input
              type="text"
              value={link}
              className="insta-link"
              placeholder="인스타그램 게시물 링크"
              onChange={(e) => {
                setLink(e.target.value);
              }}
            />
            <button
              disabled={isLoading}
              onClick={onPreview}
              className="submit"
              style={{
                backgroundColor: isLoading ? "#ddd" : "#ff3a47",
              }}
            >
              {isLoading ? "로딩중..." : "미리보기"}
            </button>
          </div>
        </div>

        <div className="order">
          <input
            value={quantity}
            type="text"
            className="quantity"
            placeholder="수량"
            onChange={(e) => {
              setQuantity(e.target.value);
              setTotalPrice(e.target.value * serviceItem.price);
            }}
          />
          <p>차감포인트: {totalPrice}</p>
          <button
            onClick={onSubmit}
            className="submit"
            disabled={isLoading}
            style={{
              backgroundColor: isLoading ? "#ddd" : "#ff3a47",
            }}
          >
            {isLoading ? "로딩중..." : "주문하기"}
          </button>
        </div>

        <div className="notice">
          <p>즉시시작하고 간헐적으로 지연될수 있습니다.</p>
          <p>접수된 주문은 취소처리가 불가능 합니다.</p>
          <p>이탈된 수량은 1달이내 리필 가능 합니다.</p>
          <p>사용법을 모르시면 꼭 카톡문의 주세요!</p>
        </div>
      </Modal>
    </ModalContaier>
  );
}
