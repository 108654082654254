import { useEffect, useState } from "react";
import { styled } from "styled-components";

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333333;
  padding: 20px 40px;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .company-name {
    font-size: 24px;
    font-weight: bold;
  }

  .footer-contact {
    font-size: 16px;
  }

  .kakao-button {
    text-decoration: none;
    background-color: #f5a623;
    color: #333333;
    font-weight: bold;
    padding: 32px 64px;
    border-radius: 4px;

    @media screen and (max-width: 768px) {
      width: 100%;
      box-sizing: border-box;
      position: fixed;
      right: 0px;
      bottom: 0px;
      padding: 20px 40px;
      ${(props) =>
        props.kakaoButtonShow ? "display: flex;" : "display: none;"}
    }
  }

  .kakao-button:hover {
    background-color: #f7b840;
  }
`;

const CompanyInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 20px;
`;

export function FooterComponent() {
  const [kakaoButtonShow, setKakaoButtonShow] = useState(false);
  useEffect(() => {
    //스크롤이 반쯤 왔을때 실행
    window.addEventListener("scroll", () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight / 2) {
        setKakaoButtonShow(true);
      } else {
        setKakaoButtonShow(false);
      }
    });
  }, []);

  return (
    <Footer kakaoButtonShow={kakaoButtonShow}>
      <CompanyInfoBox>
        <div className="company-name">&copy; 하은미디어</div>
        <div>대표: 이민기</div>
        <div>사업자등록번호: 638-15-00624</div>
        <div>주소: 서울특별시 강서구 등촌동 비원오피스텔 1501호</div>
      </CompanyInfoBox>
      <div className="footer-contact">
        <a href="카카오톡문의_링크" className="kakao-button">
          카카오톡 문의
        </a>
      </div>
    </Footer>
  );
}
