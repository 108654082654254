import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { FaBars } from "react-icons/fa";
import { logout, signIn } from "../firebase";

const NavBar = styled.div`
  position: fixed; /* 스크롤 따라 움직이도록 설정 */
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.scrollTop ? "transparent" : "rgba(0, 0, 0, 0.8)"};
  padding: 10px 20px;
  z-index: 100; /* 다른 요소 위에 나타나도록 설정 */

  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
  }

  .nav-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px;

    li {
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .menu-icon {
    display: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
  }

  p {
    color: #fff;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavMenu = styled.ul`
  display: none;
  align-items: center;
  list-style: none;
  margin: 0;
  gap: 20px;
  z-index: 10;

  li {
    padding: 5px 0;
    color: #fff;
    text-decoration: none;
    font-size: 19px;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 19px;
  }

  p {
    color: #fff;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 47px;
    left: 0;
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
    height: calc(100vh - 47px);
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform: ${(props) =>
      props.mobileMenuShow ? "translateX(0)" : "translateX(100%)"};
    transition: transform 0.5s ease-in-out;
  }
`;

const KaKao = styled.button`
  background-color: #ffcc00;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  &:hover {
    background-color: #ff6666;
    color: #fff;
  }
`;

export function NavBarComponent({ userObj, userGetLoading, userPoint }) {
  const [scrollTop, setScrollTop] = useState(true); // [scrollTop, setScrollTop
  const [mobileMenuShow, setMobileMenuShow] = useState(false); // [mobileMenuShow, setMobileMenuShow
  const route = window.location.pathname;
  useEffect(() => {
    //스크롤 탑일때 실행
    window.addEventListener("scroll", () => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop === 0) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    });
  }, []);

  const handleMobileMenuClick = () => {
    setMobileMenuShow(!mobileMenuShow);
  };

  return (
    <>
      <NavBar scrollTop={scrollTop}>
        <div className="logo">바이럴BUS</div>

        <Hamburger onClick={handleMobileMenuClick}>
          <FaBars color="white" />
        </Hamburger>

        <NavMenu mobileMenuShow={mobileMenuShow}>
          <div className="user-info">
            {userObj && <p>{userObj.displayName} 환영합니다.</p>}
            {userPoint && <p>포인트 {userPoint}</p>}
          </div>
          {!userObj && !userGetLoading ? (
            <li onClick={signIn}>구글 로그인</li>
          ) : (
            <li onClick={logout}>로그아웃</li>
          )}
          {userObj && (
            <>
              {route !== "/orders" && route !== "/payment" ? (
                <li>
                  <a href="/orders">주문 내역</a>
                </li>
              ) : (
                <li>
                  <a href="/">주문 하기</a>
                </li>
              )}

              <li>
                <a href="/payment">포인트충전</a>
              </li>
            </>
          )}
          <KaKao>카카오톡 문의하기</KaKao>
        </NavMenu>

        <ul className="nav-menu">
          {!userObj && !userGetLoading ? (
            <li onClick={signIn}>구글 로그인</li>
          ) : (
            <li onClick={logout}>로그아웃</li>
          )}
          {userObj && (
            <>
              {route !== "/orders" && route !== "/payment" ? (
                <li>
                  <a href="/orders">주문 내역</a>
                </li>
              ) : (
                <li>
                  <a href="/">주문 하기</a>
                </li>
              )}

              <li>
                <a href="/payment">포인트충전</a>
              </li>
            </>
          )}
        </ul>
      </NavBar>
    </>
  );
}
