// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
//auth
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  orderBy,
  query,
  getDoc,
  limit,
  doc,
  setDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBeqeuzZXRQr_Ff0syLzQvTqOFC3Q7p8wY",
  authDomain: "socialment.firebaseapp.com",
  projectId: "socialment",
  storageBucket: "socialment.appspot.com",
  messagingSenderId: "1052723588880",
  appId: "1:1052723588880:web:77c3034e533fe979e8bf95",
  measurementId: "G-SE4HDMMT37",
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

//auth
export const auth = getAuth();
const provider = new GoogleAuthProvider();

//limit
const queryLimit = 20;
const defaultLinkImage =
  "https://firebasestorage.googleapis.com/v0/b/socialment.appspot.com/o/noimage.png?alt=media&token=584f8e81-7bc8-4637-a526-271bd089b693";

export const signIn = () => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      // const user = result.user;
      // ...
      const user = result.user;
      const userId = user.uid;
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        //이미 존재하는 유저
      } else {
        //존재하지 않는 유저
        // Add a new document with a generated id.
        // 문서 id값을 uid로 설정
        await setDoc(doc(db, "users", userId), {
          point: 1000,
          rating: "Silver",
          createdAt: new Date(Date.now()),
          updatedAt: new Date(Date.now()),
        });
      }
    })
    .catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // The email of the user's account used.
      // const email = error.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};

//firestore
export const db = getFirestore();
export const orderCreate = async (
  link,
  quantity,
  userId,
  serviceItemId,
  serviceItemName,
  setIsLoading,
  setIsComplete,
  totalPrice,
  service,
  linkImage
) => {
  console.log(linkImage);

  await addDoc(collection(db, "orders"), {
    link: link,
    linkImage: linkImage,
    serviceItemId: serviceItemId,
    serviceName: serviceItemName,
    quantity: quantity,
    status: "ready",
    remains: quantity,
    user: userId,
    productId: serviceItemId,
    totalPrice: totalPrice,
    externalId: 0,
    service: service,
    createdAt: new Date(Date.now()),
    updatedAt: new Date(Date.now()),
  });
  setIsComplete(true);
};

export const authCheck = async () => {
  const functions = getFunctions();
  const getCurrentUser = httpsCallable(functions, "getCurrentUser");
  try {
    await getCurrentUser();
    return true;
  } catch (e) {
    return false;
  }
};

export const getOrders = async (status, userId) => {
  //Firebase Pagination
  //https://firebase.google.com/docs/firestore/query-data/query-cursors
  const q = query(
    collection(db, "orders"),
    status ? where("status", "==", status) : null,
    orderBy("createdAt", "desc"),
    where("user", "==", userId),
    limit(queryLimit)
  );
  const querySnapshot = await getDocs(q);
  const orders = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  const last = querySnapshot.docs[querySnapshot.docs.length - 1];
  return { orders, last };
};

export const getProducts = async () => {
  const q = query(collection(db, "products"), orderBy("sort"), limit(9));
  const querySnapshot = await getDocs(q);
  const products = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  const last = querySnapshot.docs[querySnapshot.docs.length - 1];
  return { products, last };
};

export const getUserPoint = async (userId) => {
  //컬렉션 users에서 id가 uid 인것을 조회한다
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().point;
  }
};

export const linkImageGet = async ({ setIsLoading, setPreviewImg, link }) => {
  const functions = getFunctions();
  const linkImageGet = httpsCallable(functions, "linkImageGet");
  setIsLoading(true);
  try {
    const result = await linkImageGet({ link: link });
    setPreviewImg(result.data);
  } catch (e) {
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};

export const createDesposit = async (userId, amount, receipt, receiptCheck) => {
  console.log(userId, amount, receipt, receiptCheck);
  //콜렉션은 desposits
  //유저아이디
  //충전금액
  //충전방법
  //현금영수증 체크여부
  //현금영수증 번호
  //충전상태 (입금대기, 입금완료, 입금취소)
  //충전일자 (현재시간)
  //충전완료일자 (입금완료시간)

  // Add a new document with a generated id.
  // 문서 id값을 uid로 설정
  await addDoc(collection(db, "desposits"), {
    userId: userId,
    amount: amount,
    receiptCheck: receiptCheck,
    receipt: receipt,
    status: "depositReady", // 입금대기 // depositComplete // 입금완료 // depositCancel // 입금취소
    createdAt: new Date(Date.now()),
    updatedAt: new Date(Date.now()),
  });
};

export const userPointCheck = async (userId, totalPrice) => {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const userPoint = docSnap.data().point;
    if (userPoint < totalPrice) {
      return false;
    }
    //update
    await setDoc(
      doc(db, "users", userId),
      {
        point: userPoint - totalPrice,
        updatedAt: new Date(Date.now()),
      },
      { merge: true }
    );
    return true;
  }
};

export const logout = () => {
  auth.signOut();
};
