import "../App.css";
import React, { useState } from "react";
import { TopScreenComponent } from "../components/TopScreenComponent";
import { UserInfoComponent } from "../components/UserInfoComponent";
import { styled } from "styled-components";
import { CategoryComponent } from "../components/CategoryComponent";
import { FaqComponent } from "../components/FaqComponent";
import { ReviewsComponent } from "../components/ReviewsComponent";
import { NavBarComponent } from "../components/NavBarComponent";
import { NoticesComponent } from "../components/NoticesComponent";
import { FooterComponent } from "../components/FooterComponent";
import { ProductListComponent } from "../components/ProductListComponent";
import { OrderModalComponent } from "../components/OrderModalComponent";

const MainContainer = styled.div``;

export default function Home({ userObj, userPoint, userGetLoading }) {
  const [serviceItem, setServiceItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  return (
    <MainContainer>
      <NavBarComponent
        userGetLoading={userGetLoading}
        userObj={userObj}
        userPoint={userPoint}
      />
      <TopScreenComponent userGetLoading={userGetLoading} userObj={userObj} />
      <UserInfoComponent userObj={userObj} userPoint={userPoint} />
      <CategoryComponent />
      <ProductListComponent
        setServiceItem={setServiceItem}
        setModalShow={setModalShow}
      />
      <FaqComponent />
      <ReviewsComponent />
      <NoticesComponent />
      <FooterComponent />
      {modalShow && (
        <OrderModalComponent
          setModalShow={setModalShow}
          serviceItem={serviceItem}
          setServiceItem={setServiceItem}
          userObj={userObj}
        />
      )}
    </MainContainer>
  );
}
