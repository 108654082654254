import { styled } from "styled-components";
import { ReviewCardComponent } from "./ReviewCardComponent";

const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;
`;

const ReviewList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ReviewTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
`;

export function ReviewsComponent() {
  return (
    <ReviewContainer>
      <ReviewTitle>소중한 리뷰</ReviewTitle>
      <ReviewList>
        {/* reviewImg, reviewText, reviewAuthor */}
        <ReviewCardComponent
          reviewTitle="인스타그램 좋아요 후기"
          reviewImg="https://cdn-icons-png.flaticon.com/512/4829/4829824.png"
          reviewText="이번에 인스타 좋아요 서비스 이용했어요! 진짜 좋아요 수가 엄청 늘었고
          인기 게시물로도 올랐답니다! 감사해요~ 서비스 정말 최고👍 앞으로도 계속
          활용하고 싶어요! 💕 다른 분들도 꼭 사용해보세요ㅎㅎ 😄"
          reviewAuthor="김라희"
          rating="★★★★☆"
        />
        <ReviewCardComponent
          reviewTitle="인스타그램 좋아요 후기"
          reviewImg="https://cdn-icons-png.flaticon.com/512/3884/3884891.png"
          reviewText="최근 인스타그램 팔로워 서비스를 이용한 후, 저의 가게 인스타그램 계정의 팔로워 수가 상당히 증가하였습니다. 이를 통해 가게 인지도도 크게 향상되었습니다. 이 서비스의 도움을 받아 가게 주변 지역 뿐만 아니라 다양한 고객들에게 인기를 얻게 되었고, 이로 인해 매장 방문 고객들 역시 늘어나는 경향을 보였습니다. 서비스를 통한 팔로워 증가는 저의 가게에 새로운 기회와 발전을 가져다주는 데 충분했습니다. 이 서비스를 이용하고 싶어 하는 다른 업체 사장님들께 고민하지 말고 이용해보라고 추천 드립니다. 제 경험에 기초해 말씀드리건대, 이 팔로워 서비스는 저와 같은 사장님들이 소셜 미디어를 통해 가게 유명세를 널리 퍼뜨리는 데 큰 도움이 될 것입니다."
          reviewAuthor="박상호"
          rating="★★★★★"
        />
        <ReviewCardComponent
          reviewTitle="인스타그램 좋아요 후기"
          reviewImg="https://cdn-icons-png.flaticon.com/512/3884/3884891.png"
          reviewText="이번에 인스타그램 좋아요 서비스를 이용한 후, 놀랄 만큼 좋아요 수가 급격하게 증가했습니다! 서비스를 사용하기 전에는 좋아요 수가 부족해서 인기 게시물로 등록되지 못했는데, 이 서비스의 도움으로 게시물의 좋아요가 무럭무럭 늘어났습니다. 진심으로 감사드리며, 이렇게 좋은 서비스를 알게 돼서 다행이라고 생각합니다. 그 결과로 인해 제 게시물은 인기 게시물로 등록되어 많은 사람들이 눈여겨 볼 수 있게 되었습니다. 이제는 더 많은 사람들과 소통할 기회가 생기고, 인스타그램에서 활발한 활동을 이어갈 수 있게 되었습니다. 서비스가 준 결과물에 대해 완전히 만족하고 있으며, 인스타그램 좋아요 서비스를 사용하고 싶어하는 다른 분들에게도 이 서비스를 추천하고 싶습니다. 좋아요 서비스는 단순한 좋아요 향상뿐만 아니라 나의 인스타그램 활동에 전반적인 향상을 가져올 것이라 확신합니다!"
          reviewAuthor="유진영"
          rating="★★★★☆"
        />
        <ReviewCardComponent
          reviewTitle="인스타그램 팔로워 후기"
          reviewImg="https://cdn-icons-png.flaticon.com/512/4829/4829824.png"
          reviewText="인스타그램 팔로워 서비스를 사용한 후, 저의 팔로워 수가 크게 증가했습니다. 덕분에 계정의 인지도가 상승하고 이로 인해 일거리도 많이 생겼어요. 이 서비스 덕분에 사회적 네트워크 확장과 함께 수익도 높일 수 있었습니다. 정말 감사합니다!"
          reviewAuthor="김현진"
          rating="★★★★★"
        />
        <ReviewCardComponent
          reviewTitle="인스타그램 조회수 후기"
          reviewImg="https://cdn-icons-png.flaticon.com/512/4829/4829824.png"
          reviewText="인스타그램 릴스 조회수 서비스를 이용한 후, 조회수가 굉장히 많이 증가했습니다. 덕분에 계정의 트래픽이 눈에 띄게 증가하였고, 이로 인해 제 게시물들이 더 많은 사람들에게 노출되었습니다. 이 서비스는 인스타그램 활동에 추가적인 가치를 가져다주어 기존보다 활동이 더욱 풍성해졌습니다. 매우 만족하고 추천합니다!"
          reviewAuthor="양지영"
          rating="★★★★★"
        />
        <ReviewCardComponent
          reviewTitle="인스타그램 좋아요 후기"
          reviewImg="https://cdn-icons-png.flaticon.com/512/4829/4829824.png"
          reviewText="인스타그램 좋아요 서비스를 이용한 후, 제 계정의 최적화가 아주 잘 이루어졌다는 것을 느꼈습니다. 이를 통해 인기 게시물로 올라가 다양한 고객들에게 노출되어 쇼핑몰 매출이 크게 상승했습니다. 이 서비스 덕분에 마케팅 효과를 극대화할 수 있었습니다. 정말 감사드리며, 이 서비스를 다른 쇼핑몰 사장님들께도 추천하고 싶습니다."
          reviewAuthor="윤미정"
          rating="★★★★★"
        />
      </ReviewList>
    </ReviewContainer>
  );
}
