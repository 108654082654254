const statusConverter = {
  ready: "준비중",
  "In progress": "진행중",
  Completed: "완료",
  Canceled: "취소됨",
  Partial: "부분환불",
  Processing: "처리중",
  Pending: "진행중",
  Fail: "처리중",
};

export const OrderItemComponent = ({ order }) => {
  const {
    link,
    quantity,
    start_count,
    remains,
    totalPrice,
    status,
    createdAt,
    linkImage,
    serviceName,
  } = order;
  return (
    <div className="orderInfoBox">
      <div className="orderInfoTextBox">
        <div>
          <img
            src={linkImage}
            alt="linkImage"
            onError={(e) => {
              e.target.src =
                "https://firebasestorage.googleapis.com/v0/b/socialment.appspot.com/o/noimage.png?alt=media&token=584f8e81-7bc8-4637-a526-271bd089b693";
            }}
          />
          <p>{createdAt.toDate().toLocaleString()}</p>
          <p>
            <strong className="service-name">{serviceName}</strong>
          </p>
          <p className="link">{link}</p>
          <p className="quantity">수량: {quantity}</p>
          <p className="start_count">시작수량: {start_count}</p>
          <p className="remains">잔여수량: {remains}</p>
          {order.status === "Canceled" ? (
            <p className="total_price">전액환불</p>
          ) : (
            <p className="total_price">차감포인트: {totalPrice}</p>
          )}
        </div>
      </div>
      <p className={`status ${status}`}>{statusConverter[status]}</p>
    </div>
  );
};
