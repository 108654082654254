import { styled } from "styled-components";

const UserInfo = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #cecece;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  p {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 20px;
    font-weight: bold;
  }
  button {
    background-color: #3aa6b9;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  button:hover {
    background-color: #fff;
    color: #ff9eaa;
    border: 1px solid #ff9eaa;
  }
  @media (max-width: 1200px) {
    margin: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
  }
`;

export function UserInfoComponent({ userObj, userPoint }) {
  if (userObj === null) return;

  return (
    <UserInfo>
      <p>{userObj.displayName}님 환영합니다.</p>
      {userPoint ? <p>현재 포인트 {userPoint}</p> : <p>확인중...</p>}
      <button>포인트 충전하기</button>
    </UserInfo>
  );
}
