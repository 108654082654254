import { OrderItemComponent } from "./OrderItemComponent";

export const OrderListComponent = ({ orders }) => {
  return (
    <>
      {!orders.length < 1 ? (
        orders.map((order) => (
          <OrderItemComponent key={order.id} order={order} />
        ))
      ) : (
        <p>주문내역이 없습니다.</p>
      )}
    </>
  );
};
