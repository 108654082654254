import { styled } from "styled-components";

const CategoryContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  padding: 10px 20px;
  font-family: "Nanum Gothic", sans-serif;
  border-radius: 5px;
  h2 {
    padding: 0;
    margin: 0;
    font-size: 35px;
    font-weight: 700;
    font-weight: bold;
  }
`;

export function CategoryComponent() {
  return (
    <CategoryContainer>
      <h2>Best Service</h2>
    </CategoryContainer>
  );
}
