import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { db, getOrders } from "../firebase";
import { TopScreenComponent } from "../components/TopScreenComponent";
import { NavBarComponent } from "../components/NavBarComponent";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { FillterButtonComponent } from "../components/FillterButtonComponent";
import { OrderListComponent } from "../components/OrderListComponent";

const fillterButtonList = [
  { name: "전체", status: null },
  { name: "취소됨", status: "Canceled" },
  { name: "진행중", status: "In progress" },
  { name: "완료", status: "Completed" },
  { name: "부분환불", status: "Partial" },
  { name: "환불", status: "Fail" },
];

const Container = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .orderInfoBox {
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
    shape-outside: circle();
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .orderInfoTextBox {
    display: flex;
    flex-direction: column;
  }
  .orderInfoTextBox p {
    padding: 5px 0px;
    margin: 0;
  }
  .link {
    color: #333;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .quantity {
    font-size: 16px;
    color: #595959;
  }
  .start_count {
    font-size: 16px;
    color: #999;
  }
  .remains {
    font-size: 16px;
    color: #999;
  }
  .total_price {
    font-size: 16px;
    color: #ff4d4f;
  }
  .status {
    background-color: #ffd700;
    color: #000080;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
  }

  .Completed {
    background-color: #d0f0c0;
    color: #424242;
  }

  .Partial {
    background-color: #ffc0cb;
    color: #424242;
  }

  .Canceled {
    background-color: #8b0000;
    color: #d3d3d3;
  }

  .Processing {
    background-color: #f1f1f1;
    color: #424242;
  }

  .In progress {
    background-color: #ffd700;
    color: #000080;
  }

  .ready {
    background-color: #ffe4b5;
    color: #8b4513;
  }
  .Pending {
    background-color: #ffd700;
    color: #000080;
  }
  .Fail {
    background-color: #8b0000;
    color: #d3d3d3;
  }
  .service-name {
    font-size: 18px;
    color: #0a3d62;
  }
  .order-search {
    display: flex;

    input {
      flex: 1;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0 10px;
      font-size: 14px;
      color: #333;
      margin-right: 10px;
    }

    button {
      width: 100px;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f5f5f5;
      }

      &:active {
        background-color: #e6e6e6;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #f5f5f5;
        color: #ccc;
        cursor: not-allowed;
      }
    }
  }
`;

const FillterButtons = styled.div`
  display: flex;
  gap: 10px;
  button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #ffd700;
    color: #000080;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      background-color: #000080;
      color: #ffd700;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    button {
      margin-bottom: 10px;
      font-size: 12px;
  }
`;

const NextButton = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export default function OrdersComponent({
  userObj,
  userGetLoading,
  userPoint,
}) {
  const [orders, setOrders] = useState([]); // [{}
  const [lastOrder, setLastOrder] = useState(null); // [{}
  const [statusFilter, setStatusFilter] = useState(null); // [{}
  // const [searchText, setSearchText] = useState(null); // [{}
  const queryLimit = 20;
  // const observerRef = React.useRef(null);

  const nextOrders = async () => {
    //스크롤이 아래로 내려갔을때 다음 주문내역을 가져온다
    // if (!lastOrder) return;
    try {
      const next = await getDocs(
        query(
          collection(db, "orders"),
          statusFilter ? where("status", "==", statusFilter) : null,
          orderBy("createdAt", "desc"),
          startAfter(lastOrder),
          limit(queryLimit)
        )
      );
      if (next.empty) {
        console.log("끝");
        return;
      }
      const last = next.docs[next.docs.length - 1];
      const nextOrders = next.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      if (nextOrders.length < queryLimit) {
        setLastOrder(null);
        setOrders((prev) => [...prev, ...nextOrders]);
        return;
      }
      setLastOrder(last);
      setOrders((prev) => [...prev, ...nextOrders]);
    } catch (e) {
      console.log(e);
    }
  };

  // const onSearch = async () => {
  //   if (!searchText) {
  //     setOrders([]);
  //     return;
  //   }
  //   const q = query(
  //     collection(db, "orders"), // 포스트 컬렉션
  //     where("keyword", "array-contains", searchText.toLowerCase())
  //     // where("serviceName", ">=", searchText),
  //     // where("serviceName", "<=", searchText + "\uf8ff")
  //   );

  //   const querySnapshot = await getDocs(q);
  //   const orders = querySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));
  //   setOrders(orders);
  // };

  // useEffect(() => {
  //   // 스크롤이 옵저버 ref div에 닿으면 nextOrders 실행
  //   const handleObserver = async (entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting) {
  //       if (isLoading) {
  //         return;
  //       } else {
  //         await nextOrders();
  //       }
  //       console.log("handleObserver 실행");
  //     }
  //   };
  //   // 옵션 객체 정의:
  //   const option = {
  //     root: null, // 상대적으로 관찰할 대상 요소를 지정. null인 경우 브라우저의 viewport를 기준으로 합니다.
  //     rootMargin: "50px", // 관찰 대상 요소를 기준으로 한 여백을 지정합니다. 이 값이 있으면 특정 범위 안에서도 콜백 함수가 실행됩니다.
  //     threshold: 0.1, // 관찰 대상 요소의 가시성 비율을 0과 1 사이의 숫자로 지정합니다. 여기서는 50% 이상 보일 때 콜백 함수가 실행됩니다.
  //   };

  //   const observer = new IntersectionObserver(handleObserver, option);
  //   if (observerRef.current) observer.observe(observerRef.current);
  // }, [observerRef.current]);

  useEffect(() => {
    const getOrderList = async () => {
      const { orders, last } = await getOrders(statusFilter, userObj.uid);
      if (orders.length < queryLimit) {
        setLastOrder(null);
        setOrders(orders);
        return;
      }
      setOrders(orders);
      setLastOrder(last);
    };
    if (userObj) getOrderList();
    // return () => setOrders([]);
  }, [statusFilter, userObj, userGetLoading]);

  if (userGetLoading)
    return (
      <>
        <NavBarComponent userGetLoading={userGetLoading} userObj={userObj} />
        <TopScreenComponent userGetLoading={userGetLoading} userObj={userObj} />
        <Container>
          <h1>주문내역</h1>
          <p>로딩중...</p>
        </Container>
      </>
    );

  if (!userObj) {
    return (
      <>
        <NavBarComponent userObj={userObj} userGetLoading={userGetLoading} />
        <TopScreenComponent userGetLoading={userGetLoading} userObj={userObj} />
        <Container>
          <h1>주문내역</h1>
          <p>로그인이 필요합니다.</p>
        </Container>
      </>
    );
  }

  return (
    <>
      <NavBarComponent
        userObj={userObj}
        userGetLoading={userGetLoading}
        userPoint={userPoint}
      />
      <TopScreenComponent
        userGetLoading={userGetLoading}
        userObj={userObj}
        userPoint={userPoint}
      />
      <Container>
        <h1>주문내역</h1>
        <FillterButtons>
          {fillterButtonList.map((button, index) => (
            <FillterButtonComponent
              key={index}
              buttonName={button.name}
              setStatusFilter={setStatusFilter}
              status={button.status}
            />
          ))}
        </FillterButtons>
        {/* <div className="order-search">
          <input
            value={searchText}
            type="text"
            placeholder="링크, 서비스명"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button onClick={onSearch}>검색</button>
        </div> */}
        <OrderListComponent orders={orders} />
        {/* <div ref={observerRef}></div> */}
        {lastOrder && <NextButton onClick={nextOrders}>더보기</NextButton>}
      </Container>
    </>
  );
}
