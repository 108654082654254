import { styled } from "styled-components";

const NoticeContainer = styled.div`
  max-width: 1200px;
  margin: 70px auto;
  padding: 0 20px;

  .notice-list {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .notice-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    border-bottom: 1px solid #e0e0e0;
  }

  .notice-item:last-child {
    border-bottom: none;
  }

  .notice-item a {
    display: flex;
    width: 100%;
    align-items: center;
    text-decoration: none;
    color: #777777;
  }

  .notice-item a:hover {
    color: #333333;
  }

  .notice-date {
    font-size: 15px;
  }

  .notice-text {
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    .notice-text {
      font-size: 15px;
    }
  }
`;

export function NoticesComponent() {
  return (
    <NoticeContainer>
      <h1 className="notice-title">공지사항</h1>
      <ul className="notice-list">
        <li className="notice-item">
          <a href="공지사항1_링크">
            <span className="notice-date">2023.09.22</span>
            <span className="notice-text">
              인스타그램 외국인 좋아요 잠시 지연중....
            </span>
          </a>
        </li>
        <li className="notice-item">
          <a href="공지사항2_링크">
            <span className="notice-date">2023.09.20</span>
            <span className="notice-text">
              인스타그램 조회수 서비스가 지연되고 있습니다.
            </span>
          </a>
        </li>
        <li className="notice-item">
          <a href="공지사항3_링크">
            <span className="notice-date">2023.09.18</span>
            <span className="notice-text">
              9월 한달간 팔로워 이벤트 진행하고 있습니다.
            </span>
          </a>
        </li>
      </ul>
    </NoticeContainer>
  );
}
