import { styled } from "styled-components";

const FaqContainer = styled.div`
  max-width: 1200px;
  padding: 10px 20px;
  margin: 30px auto;
  h1 {
    color: #3498db;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  li:hover {
    background-color: #f1f1f1;
  }
  .question {
  }
  .answer {
    color: #555;
  }
`;

const Question = styled.p`
  font-weight: bold;
  color: #3498db;
  margin-bottom: 5px;
`;

const Answer = styled.p`
  color: #555;
  max-width: 800px;
`;

export function FaqComponent() {
  return (
    <FaqContainer>
      <ul>
        <li>
          <Question>Q1. 서비스는 언제 시작 되나요?</Question>
          <Answer>
            A1. 모든 서비스는 즉시(10분이내) 시작되며, 간혹 간헐적으로 지연될수
            있습니다. 오랫동안 지연될경우 자동 환불처리 되거나 고객센터로
            문의주시면 빠르게 처리 도와 드리고 있습니다.
          </Answer>
        </li>
        <li>
          <Question>
            Q2. 인스타그램 팔로워나 좋아요가 이탈되는 경우도 있나요?
          </Question>
          <Answer>
            A2. 인스타그램 자체 업데이트나 사용자 언팔로 인하여 좋아요나
            팔로워가 이탈되는 경우는 간혹 있습니다. 이탈이 되었을경우 1달이내
            리필버튼으로 리필해주시거나 자동으로 리필처리 도와 드리고 있습니다.
          </Answer>
        </li>
        <li>
          <Question>Q3. 결제는 어떻게 해야 하나요?</Question>
          <Answer>
            A3. 포인트 충전을 통해 결제를 진행해 주시면 충전된 포인트만큼
            서비스를 구매하여 이용하실수 있습니다.
          </Answer>
        </li>
      </ul>
    </FaqContainer>
  );
}
