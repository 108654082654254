export const FillterButtonComponent = ({
  buttonName,
  status,
  setStatusFilter,
}) => {
  return (
    <button
      onClick={() => {
        setStatusFilter(status);
      }}
    >
      {buttonName}
    </button>
  );
};
