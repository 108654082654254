import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../route/Home";
import OrdersComponent from "../route/OrdersComponent";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import Payment from "../route/Payment";

export default function RouterApp() {
  const [userObj, setUserObj] = useState(null);
  const [userGetLoading, setUserGetLoading] = useState(true);
  const [userPoint, setUserPoint] = useState(0);
  useEffect(() => {
    // authCheck();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //access token
        setUserObj(user);
      } else {
        setUserObj(null);
      }
      setUserGetLoading(false);
    });
  }, []);

  useEffect(() => {
    const userId = userObj?.uid;
    if (userId) {
      // userid값을 users에서 onSnapshot으로 가져오기
      const docRef = doc(db, "users", userId);
      onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setUserPoint(data.point);
        }
      });
    }

    if (!userId) setUserPoint(null);
  }, [userObj]);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                userObj={userObj}
                userPoint={userPoint}
                userGetLoading={userGetLoading}
              />
            }
          />
          <Route
            path="/orders"
            element={
              <OrdersComponent
                userObj={userObj}
                userGetLoading={userGetLoading}
                userPoint={userPoint}
              />
            }
          />
          <Route
            path="/payment"
            element={
              <Payment
                userObj={userObj}
                userGetLoading={userGetLoading}
                userPoint={userPoint}
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
}
