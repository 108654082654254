import { styled } from "styled-components";

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  .review-img {
    width: 65px;
    height: auto;
    padding: 10px 16px;
    object-fit: cover;
  }
`;
const CardHeader = styled.div`
  padding: 12px 16px;
  background-color: #fafafa;
  border-bottom: 2px solid #e0e0e0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .rating {
    font-size: 18px;
    color: #f5a623;
  }
`;

const CardBody = styled.div`
  padding: 16px;
`;

const ReviewText = styled.p`
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
  color: #777777;
  line-height: 1.5;
`;

const ReviewAuthor = styled.p`
  font-size: 14px;
  color: #999999;
`;

export function ReviewCardComponent({
  reviewTitle,
  reviewImg,
  reviewText,
  reviewAuthor,
  rating,
}) {
  return (
    <Card>
      <img src={reviewImg} alt="리뷰 이미지" className="review-img" />
      <CardHeader>
        <h2>{reviewTitle}</h2>
        <div className="rating">
          <span>{rating}</span>
        </div>
      </CardHeader>
      <CardBody>
        <ReviewText>{reviewText}</ReviewText>
        <ReviewAuthor>리뷰 작성자: {reviewAuthor}</ReviewAuthor>
      </CardBody>
    </Card>
  );
}
