import { styled } from "styled-components";
import { ProductItemComponent } from "./ProductItemComponent";
import { useEffect, useState } from "react";
import { getProducts } from "../firebase";

const ProductList = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 50px 20px;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

// const services = [
//   {
//     id: 1,
//     name: "인스타그램 외국인 팔로워",
//     image: "https://cdn-icons-png.flaticon.com/512/2097/2097654.png",
//     description:
//       "인스타그램 팔로워 서비스는 계정의 팔로워 수를 증가시켜 인지도를 높이고 소통을 확장시켜주는 효과적인 솔루션입니다.",
//     price: 4,
//     isKorean: false,
//   },
//   {
//     id: 2,
//     name: "인스타그램 외국인 좋아요",
//     image: "https://cdn-icons-png.flaticon.com/512/1635/1635477.png",
//     description:
//       "인스타그램 좋아요 서비스는 게시물에 표시되는 좋아요 수를 높여 인기도를 높이고 더 많은 사용자와 공유할 수 있는 도움을 제공합니다.",
//     price: 0.5,
//     isKorean: false,
//   },
//   {
//     id: 3,
//     name: "인스타그램 한국인 댓글",
//     image: "https://cdn-icons-png.flaticon.com/512/8966/8966287.png",
//     description:
//       "인스타그램 댓글 서비스는 게시물에 댓글 수를 늘려 사용자 간의 소통을 촉진하고 활발한 인터랙션을 도와주는 기능입니다.",
//     price: 300,
//     isKorean: true,
//   },
//   {
//     id: 4,
//     name: "인스타그램 조회수",
//     image: "https://cdn-icons-png.flaticon.com/512/3295/3295589.png",
//     description:
//       "인스타그램 조회수 서비스는 동영상 게시물의 재생 횟수를 증가시켜 유입 트래픽과 인기도를 높이는 효과를 제공하는 솔루션입니다.",
//     price: 0.5,
//     isKorean: false,
//   },
//   {
//     id: 5,
//     name: "인스타그램 노출 도달",
//     image: "https://cdn-icons-png.flaticon.com/512/9431/9431787.png",
//     description:
//       "인스타그램 노출 도달 서비스는 게시물이 더 많은 사용자에게 노출되도록 도와 인기도와 영향력을 상승시키는 전략적인 플랫폼입니다.",
//     price: 0.5,
//     isKorean: false,
//   },
//   {
//     id: 6,
//     name: "인스타그램 스토리 조회수",
//     image: "https://cdn-icons-png.flaticon.com/512/2595/2595648.png",
//     description:
//       "인스타그램 스토리 조회수 서비스는 스토리 게시물의 조회수를 증가시켜 유입 트래픽과 인기도를 높이는 효과를 제공하는 솔루션입니다.",
//     price: 0.5,
//     isKorean: false,
//   },
// ];

export function ProductListComponent({ setServiceItem, setModalShow }) {
  const [services, setServices] = useState([]);
  useEffect(() => {
    const getProductsData = async () => {
      const { products, last } = await getProducts();
      setServices(products);
    };
    getProductsData();
  }, []);

  return (
    <ProductList>
      {services.map((service) => (
        <ProductItemComponent
          key={service.id}
          service={service}
          setServiceItem={setServiceItem}
          setModalShow={setModalShow}
        />
      ))}
    </ProductList>
  );
}
